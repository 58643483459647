// eslint-disable-next-line no-redeclare,no-unused-vars
function optionValue(param) {
  if (!param.settings || isEmptyObject(param.settings)) return;

  var optionKey;
  var value;
  var cond = [];

  // conditions
  if (param.objectSub) cond.push(ucfirst(param.objectSub));
  cond.push(ucfirst(role));
  if (mobile == 'smartphone') cond.push('Smartphone');

  // index condition
  if (param.index && param.index == 'any')
    for (var i = 2; i < 6; i++) {
      if (param.settings[param.key + 'Field' + i]) cond.push('Field' + i);
    }
  else if (param.index) cond.push('Field' + param.index);

  // go in exactly this order and search for option
  $.each(
    [[1, 2, 3], [1, 2], [1, 3], [1], [2, 3], [2], [3], 0],
    function (i, nos) {
      var add = '';
      if (nos)
        $.each(nos, function (j, no) {
          if (cond[no - 1]) add += cond[no - 1];
        });
      optionKey = param.key + add;
      if (isset(param.settings[optionKey])) {
        value = param.settings[optionKey];
        param.optionKey = optionKey;
        return false;
      }
    }
  );

  return value;
}
